<template>
    <div id="inviter">
        <div class="admin_main_block">
            <div class="user_temp_title">升级VIP</div>
            <div class="admin_table_main">
                <div class="inviter_main">
                    <div class="title" v-for="item in vipList" v-if="item.conditionNum>0">
                        推荐
                        <font color="#f80">{{item.conditionNum}}</font>
                        人注册，立即成为
                        <font color="#f80">{{item.name}}</font>！
                    </div> 
                    <!-- <div class="url">我发现一个好用礼品代发网站，你也看看，注册网址是 {{inviteUrl}}</div> -->
                    <div class="btn">
                        <el-button size="small" type="primary" @click="copy">复制网址，邀请好友</el-button>
                    </div> 
                    <!-- <div class="success">您已成功邀请<font color="#f80">{{$store.state.userInfo.inviteCount}}</font>人</div> 
                    <div class="tip">
                        邀请好友永久提成奖励！<br>
                        推荐收益计算方式 = 被邀请人支付订单包裹数 * {{$store.state.tenantInfo.spreadAmount}}元/单 <br>
                    </div> -->
                </div>
            </div>
            <div class="user_temp_title" style="margin-top: 40px;">我的推荐商家</div>
            <div class="admin_table_main" style="margin-top: 40px;">
                <el-table size="small" :data="list" v-loading = 'loading'>
                    <!-- <el-table-column label="昵称" width="590"></el-table-column> -->
                    <el-table-column label="手机号码" prop="phone"></el-table-column>
                    <el-table-column label="注册时间">
                        <template slot-scope="scope">
                            <span>{{$time.getDate(scope.row.createTime)}}</span>
                        </template>
                    </el-table-column>
                </el-table>
                <div style="margin-top: 30px;margin;text-align:right;">
                    <el-pagination
                        v-if="total>0"
                        background 
                        @current-change="handleCurrentChange" 
                        :current-page="searchData.pageNo" 
                        :page-size="searchData.pageSize" 
                        layout="total, prev, pager, next, jumper" 
                        :total="total">
                    </el-pagination>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: '',
    data() {
        return {
            inviteUrl:"",
            vipList:[],
            loading:false,//防重复点击
            searchData:{
                pageNo:1,
                pageSize:10,
                id: localStorage.getItem("ids")
            },
            list:[],
            total:0,
        }
    },
    created(){
        this.getInviteUrl();
        this.getVipList();
        this.getList();
    },
    mounted(){},
    watch: {},
    methods: {
        getInviteUrl(){
            this.$request.post({
                url:'/ReportApi/business/getInviteCode',
                params:{
                    id: localStorage.getItem("ids")
                },
                success: res => {
                    this.inviteUrl=window.location.protocol+"//"+window.location.host+"/?inviteCode="+res;
                },
                finally: () => {
                }
            })
        },
        copy(){
            var input = document.createElement("input");
            input.value = this.inviteUrl;
            document.body.appendChild(input);
            input.select();
            input.setSelectionRange(0, input.value.length), document.execCommand('Copy');
            document.body.removeChild(input);
            this.$message.success('复制成功!');
        },
        getVipList(){
            this.$request.post({
                url:'/ReportApi/business/levelList',
                params:{
                     tenantId: localStorage.getItem("tenantInfoid")
                },
                success: res => {
                    this.vipList = res;
                },
                finally: () => {
                }
            })
        },
        getList(){
            this.loading = true;
            this.$request.post({
                url:'/ReportApi/business/spreadList',
                params:this.searchData,
                success: res => {
                    this.list = res[0];
                    this.total = res[1];
                },
                finally: () => {
                    this.loading = false;
                }
            })
        },
        handleCurrentChange(val){
            this.searchData.pageNo = val;
            this.getList();
        },
    }
}
</script>

<style lang="scss" scoped>
#inviter{
    .admin_main_block{
        background: #fff;
        width: 100%;
        min-height: 855px;
        border-radius: 4px;
        box-sizing: border-box;
        padding: 20px;
        .user_temp_title{
            margin: auto;
            padding-left: 20px;
            border-bottom: 1px solid #eeeeee;
            padding-bottom: 20px;
            height: 25px;
            margin-bottom: 20px;
            font-weight: bold;
        }
        .admin_table_main{
            .inviter_main{
                margin: 40px 80px 0 80px;
                .title{
                    font-size: 15px;
                    line-height: 36px;
                }
                .url{
                    border: 1px solid #efefef;
                    border-radius: 6px;
                    line-height: 40px;
                    font-size: 14px;
                    padding-left: 10px;
                    margin-bottom: 20px;
                }
                .success{
                    font-size: 18px;
                    line-height: 90px;
                }
                .tip{
                    color: #f80;
                    background: #ffefe2;
                    padding: 18px 22px;
                    line-height: 30px;
                }
            }
        }
    }
}
</style>